import React from 'react'
import { SuperSEO } from "react-super-seo";

const SEO = () => {
  return (
    <SuperSEO
        title="uzoma://studio"
        description="Creative technology studio for artists and creative brands"
        lang="en"
        openGraph={{
            ogImage: {
            ogImage: "../images/fractal.png",
            ogImageAlt: "uzoma studio logo",
            ogImageWidth: 1200,
            ogImageHeight: 630,
            ogImageType: "image/jpeg",
            },
        }}
        twitter={{
            twitterSummaryCard: {
            summaryCardImage: "../images/fractal.png",
            summaryCardImageAlt: "uzoma studio logo",
            summaryCardSiteUsername: "uzoma://studio",
            },
        }}
        />
  )
}

export default SEO