import './App.css';
import logo from './images/fractal.png'
import Chat from './components/chat'
import SEO from './components/seo';
import { FaEnvelope, FaInstagram } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='App-header-logo'>
          <img src={logo} alt="black concentric circles" />
          <div className='App-header-logo-text'>
            <p>uzoma://studio</p>
            <small>love is our technology</small>
          </div>
        </div>
        <div className='App-header-contact'>
          <a href="mailto:hello@uzoma.studio" target="_blank" rel="noopener noreferrer"><FaEnvelope /></a>
          <a href="https://instagram.com/uzoma.studio" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
      </header>
      <main>
        <Chat />
      </main>
      <SEO />
    </div>
  );
}

export default App;
