import React, { useState, useEffect, useRef } from 'react'
import '../styles/chat.css'
import { nodeIds, nodes, replyOptionsText } from '../data/nodes.js'

const Chat = () => {

    const { welcome, end, industries } = nodeIds

    const [nodesToShowIds, setNodesToShowIds] = useState([welcome])

    const bottomRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [nodesToShowIds]);

    const getCurrentNode = (id) => {
        return nodes.find(message => message.id === id)
    }

    const createMessageNode = (id) => getCurrentNode(id).messages.map((message, index) => <div key={index} className='chat-box chat-box-text' style={{ animationDelay: `${index+1}s` }} dangerouslySetInnerHTML={{__html: message}}/>)

    const initiateButtonAction = (id) => {
        setNodesToShowIds([...nodesToShowIds, id])
    }

    const getReplyOptions = (id) => {
        const node = getCurrentNode(id)
        const animationDelay = node.messages.length + 1 //the time in s that the reply option buttons should wait before display
        // calculated as length of the messages array + 1 (length of each animation set in chat.css)
        return node.replies.map(({ id, text }) => 
            <p className="reply-option" style={{ animationDelay: `${animationDelay}s` }}>
                <button onClick={() => initiateButtonAction(id)}>{text}</button>
            </p>
        )
    }

    const minNodesToShow = 4

  return (
    <div className='chat' id='chat'>
        {
            nodesToShowIds.map(
                (nodeId) =>
                    <>
                        <div className='studio-messages' ref={bottomRef}>{ createMessageNode(nodeId) }</div>
                        <div className='reply-options'> 
                            { getReplyOptions(nodeId) } 
                            
                            {/* Give the visitor the option to end the chat if a certain number of interactions has been exceeded
                            and if they are not currently in a loop of viewing project ideas */}

                            { nodesToShowIds.indexOf(nodeId) > minNodesToShow && !Object.values(industries).includes(nodeId) && nodeId !== end &&
                                <p className='reply-option' style={{ animationDelay: `${getCurrentNode(nodeId).messages.length + 1}s` }}>
                                    <button onClick={() => initiateButtonAction(end)}>{replyOptionsText.end}</button>
                                </p>
                            }
                        </div>
                    </>
            )
        }
    </div>
  )
}

export default Chat