import isah from '../images/portfolio/isah-the-prince.gif'
import akra from '../images/portfolio/a-kra.gif'
import iamisigo from '../images/portfolio/iamisigo.gif'
// import abuja from '../images/abuja.webm'
import abujaJpg from '../images/abuja.jpg'

const nodeIds = {
    projects: 'projects',
    alternet: 'alternet',
    welcome: 'welcome',
    collaborate: 'collaborate',
    love: 'love',
    process: 'process',
    contact: 'contact',
    industries: {music: "music", fashion: "fashion", culture: "culture", visualArts: "visual arts", filmTv: "film & tv", media: "media", literature: "literature & publishing", theatre: "theatre & performing arts", photography: "photography", designArc: "design & architecture"},
    continuation: 'continuation',
    location: 'location',
    team: 'team',
    end: 'end'
}

const { projects, alternet, welcome, love, process, contact, continuation, location, team, end } = nodeIds

// Reply Options text
const replyOptionsText = {
    projects: "What projects have you worked on?",
    alternet: "Tell me more about the studio's vision",
    love: "What do you mean by 'love is our technology'?",
    collaborate: "Who do you collaborate with?",
    process: "How do you work?",
    contact: "How can I contact you?",
    location: "Where are you based?",
    team: "Who is behind uzoma.studio?",
    end: "Thanks for all the info, I think I'm good to go"
}

const getUrl = (url, linkText) => `<a href='${url}' target='_blank' rel='noopener noreferrer'>${linkText}</a>`
// const alternetLink = getUrl('https://www.are.na/uzoma-orji/the-alternet', 'The Alternet')
// const alternetLink2 = getUrl('https://www.are.na/uzoma-orji/the-alternet', 'alternet.uzoma.studio')

const projectDetails = [
    {
        id: 'iamisigo',
        title: 'IAMISIGO',
        image: iamisigo,
        description: "An interactive web experience for an eclectic fashion brand",
        url: 'https://iamisigo.com'
    },
    {
        id: 'isah',
        title: 'Isah The Prince',
        image: isah,
        description: "A portal into the world of a musician's album",
        url: 'https://isahtheprince.com'
    },
    {
        id: 'kra',
        title: 'Kra Studio',
        image: akra,
        description: "A cosmic experience for a virtual artist residency and art education platform",
        url: 'https://akra.studio'
    },
]

const displayProject = (projectId) => {
    const project = projectDetails.find(({ id }) => id === projectId)
    const { image, title, description, url } = project
    
    return `<div>
                <img src=${image} alt=${title} />
                <p>${title}</p>
                <p>${description}</p>
                <a href=${url} target='_blank' rel='noopener noreferrer'>Link</a>
            </div>`
}

const nodes = [
    {
        id: welcome,
        messages: [
            "Hey there, welcome",
            "uzoma.studio creates interactive websites & digital experiences for artists and creative brands",
            "We are particularly inspired by projects at the intersection of art, culture, technology and African worldbuilding",
            "What would you like to find out today?"
        ],
        replies: [
            { id: alternet, text: replyOptionsText.alternet },
            { id: projects, text: replyOptionsText.projects },
        ]
    },
    {
        id: alternet,
        messages: [
            "To us, the internet is a powerful portal for dreaming towards the future we wish to inhabit today",
            "uzoma.studio is therefore on a mission to create the internet that allows us dream most freely - one which is embodied, communal, care-full, playful and rooted in ancestral wisdom",
            "We seek to do this through collaborations with visionary artists whose practices align with this ethos",
            "By activating their work within the digital space, we birth an internet that points towards a liberated future one project at a time"
        ],
        replies: [
            { id: projects, text: replyOptionsText.projects },
            { id: process, text: replyOptionsText.process },
            { id: love, text: replyOptionsText.love }
        ]
    },
    {
        id: projects,
        messages: [
            `Some of our past work includes:`,
            displayProject('iamisigo'),
            displayProject('isah'),
            displayProject('kra')
        ],
        replies: [
            { id: alternet, text: replyOptionsText.alternet },
            { id: process, text: replyOptionsText.process },
            { id: contact, text: replyOptionsText.contact }
        ]
    },
    // {
    //     id: collaborate,
    //     messages: [
    //         "uzoma.studio collaborates with artists, changemakers and world-builders whose work in some way aligns with our vision of a liberated, healed and free African psyche",
    //         "Our work mostly involves us building them interactive websites (for us a website is a portal) which allows their audience experience their work in its fullest form",
    //         `Our collaborators work across the creative industries and have included IAMISIGO (fashion), Isah The Prince (music) and A-Kra Studio (art). You can see more collaborators at ${alternetLink2}`,
    //         "We would love to hear from you if you're interested in collaborating...please get in touch!"
    //     ],
    //     replies: [
    //         { id: alternet, text: replyOptionsText.alternet },
    //         { id: love, text: replyOptionsText.love },
    //         { id: contact, text: replyOptionsText.contact },
    //         { id: team, text: replyOptionsText.team },
    //     ]
    // },
    {
        id: continuation,
        messages: [
            "Nice! Is there anything else you'd like to know?"
        ],
        replies: [
            { id: projects, text: replyOptionsText.projects },
            { id: process, text: replyOptionsText.process },
            { id: contact, text: replyOptionsText.contact },
            { id: location, text: replyOptionsText.location },
        ]
    },
    {
        id: location,
        messages: [
            // `<video autoplay loop width='250'><source src=${abuja} type='video/webm' /></video>`,
            `<img src=${abujaJpg} width='250 alt='A collage of landmarks in abuja' />`,
            "IRL you can find us somewhere among the serene hills of Abuja, Nigeria"
        ],
        replies: [
            { id: projects, text: replyOptionsText.projects },
            { id: contact, text: replyOptionsText.contact },
            { id: team, text: replyOptionsText.team },
        ]
    },
    {
        id: love,
        messages: [
            "'Love is our technology' encapsulates our belief that for the African, the algorithm that mediates the experience of and engagement with life is love"
        ],
        replies: [
            { id: projects, text: replyOptionsText.projects },
            { id: contact, text: replyOptionsText.contact },
            { id: location, text: replyOptionsText.location },
        ]
    },
    {
        id: process,
        messages: [
            `A typical project follows these stages of evolution: 
            <ol><li>Initial conversations</li><li>Project research</li><li>Idea conceptualisation</li><li>User interface design</li><li>Web design and development</li><li>Project deployment</li></ol>We take a very hands-on, iterative approach involving a lot of communication with our collaborator.`
        ],
        replies: [
            { id: projects, text: replyOptionsText.projects },
            { id: team, text: replyOptionsText.team },
            { id: contact, text: replyOptionsText.contact },
        ]
    },
    {
        id: team,
        messages: [
            `Artist and creative technologist Uzoma Orji and a dynamic team of creative collaborators. You can see more of Uzoma's work ${getUrl('https://uzomaorji.com', 'here')}`
        ],
        replies: [
            { id: location, text: replyOptionsText.location },
            { id: contact, text: replyOptionsText.contact },
        ]
    },
    {
        id: contact,
        messages: [
            `You can send us an email at ${getUrl('mailto:portal@uzoma.studio', 'portal@uzoma.studio')}`
        ],
        replies: [
            { id: projects, text: replyOptionsText.projects },
            { id: location, text: replyOptionsText.location },
            { id: team, text: replyOptionsText.team },
        ]
    },
    {
        id: end,
        messages: [
            "Thanks for stopping by, have a beautiful rest of your day"
        ],
        replies: []
    }
]

export { nodeIds, nodes, replyOptionsText}
